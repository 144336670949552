const __o365_sfc__ = {};
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "icon-wrapper"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [_createElementVNode("svg", {
    class: "ov-icon",
    "aria-hidden": "true",
    width: "28",
    height: "28",
    viewBox: "0.48 0.48 23.04 23.04",
    fill: "#fff"
  }, [_createElementVNode("path", {
    fill: "none",
    d: "M0 0h24v24H0V0z"
  }), _createElementVNode("path", {
    d: "M18.3 5.71a.996.996 0 00-1.41 0L12 10.59 7.11 5.7A.996.996 0 105.7 7.11L10.59 12 5.7 16.89a.996.996 0 101.41 1.41L12 13.41l4.89 4.89a.996.996 0 101.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
  })], -1 /* HOISTED */)]));
}
    __o365_sfc__.name = 'CloseButton';
    __o365_sfc__.__file = 'CloseButton';
    try {
__o365_sfc__.render = render;
} catch (ex) {
    window['console'].warn(ex);
}
    __o365_sfc__.__scopeId = 'data-v-18061ebf-a03a-4f66-8506-a07d1195ad41';
    export default __o365_sfc__;
            let style = document.createElement('style');
            style.setAttribute('type', 'text/css');
            style.innerHTML = `
.icon-wrapper[data-v-18061ebf-a03a-4f66-8506-a07d1195ad41] {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

`;
            document.head.appendChild(style);
            